<template>
  <div>
    <ValidationObserver ref="form">
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar elevation="0">
              <v-btn @click="$router.go(-1)" icon>
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-toolbar-title class="primary--text">
                {{ pageTitle }}
              </v-toolbar-title>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :loading="fetching_report">
          <v-alert type="error" ref="" v-if="errors.length > 0">
            <div class="font-weight-bold">
              {{ __("validações", "products") }}
            </div>
            <ol>
              <li v-for="(error, index) in errors" :key="index">
                {{ error }}
              </li>
            </ol>
          </v-alert>
          <v-card outlined>
            <v-card-title>{{ __("peritagem", "final_report") }} </v-card-title>
            <v-card-text>
              <ValidationProvider
                rules="required"
                name="final_report.analysis_scheduling_types"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="lead_form.analysis_scheduling_types"
                  :items="scheduling_types"
                  :loading="loading_scheduling_types"
                  item-text="description"
                  item-value="canonical"
                  :label="__('tipos de peritagem', 'final_report')"
                  multiple
                  outlined
                  :readonly="editMode"
                  :error-messages="errors"
                  chips
                  small-chips
                  deletable-chips
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-icon>
                      <v-icon>mdi-{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ item.description }}
                    </v-list-item-title>
                  </template>
                </v-select>
              </ValidationProvider>
              <v-row dense>
                <v-col>
                  <ValidationProvider
                    name="final_report.franchise_total_value"
                    v-slot="{ errors }"
                  >
                    <MoneyInput
                      v-model="lead_form.franchise_total_value"
                      :label="__('franquia', 'form_lead')"
                      :error-messages="errors"
                      :readonly="editMode"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    name="final_report.has_no_franchise_total_value"
                    :rules="
                      lead_form.franchise_total_value == 0
                        ? { required: { allowFalse: false } }
                        : ''
                    "
                    v-slot="{ errors }"
                  >
                    <v-checkbox
                      class="mt-3"
                      v-model="lead_form.has_no_franchise_total_value"
                      :label="__('aceitar franquia zerada', 'final_report')"
                      :error-messages="errors"
                      :readonly="editMode"
                      :disabled="lead_form.franchise_total_value > 0"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <ValidationProvider
                    name="final_report.capital"
                    :rules="
                      lead_form.capital == 0
                        ? { required: { allowFalse: false } }
                        : ''
                    "
                    v-slot="{ errors }"
                  >
                    <MoneyInput
                      v-model="lead_form.capital"
                      :label="__('Capital seguro', 'form_lead')"
                      :error-messages="errors"
                      :readonly="editMode"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <ValidationProvider
                rules="required"
                name="final_report.origin_type_id"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  outlined
                  :label="__('origem/Causa', 'report_visit')"
                  v-model="lead_form.origin_type_id"
                  item-value="id"
                  item-text="description"
                  :items="available_origin_types"
                  :clearable="createMode"
                  :error-messages="errors"
                  :loading="fecthing_available_origin_types"
                  :readonly="editMode"
                />
              </ValidationProvider>
              <ValidationProvider
                rules="required"
                name="final_report.established_facts"
                v-slot="{ errors }"
              >
                <v-textarea
                  outlined
                  :label="__('fatos apurados', 'final_report')"
                  :rows="10"
                  auto-grow
                  v-model="lead_form.established_facts"
                  :error-messages="errors"
                  :readonly="editMode"
                >
                  <template v-slot:append>
                    <v-menu left :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-on="on"
                          v-bind="attrs"
                          :disabled="
                            products_with_technical_analysis.length === 0
                          "
                        >
                          <v-icon>mdi-lightbulb-on</v-icon>
                        </v-btn>
                      </template>
                      <v-list min-width="160">
                        <v-subheader class="font-weight-medium">{{
                          __("sugestões", "final_report")
                        }}</v-subheader>
                        <v-list-item
                          v-for="product in products_with_technical_analysis"
                          :key="product.id"
                        >
                          <v-checkbox
                            v-model="suggestion_products"
                            :label="product.name"
                            :value="product"
                            hide-details
                          ></v-checkbox>
                        </v-list-item>
                        <v-list-item class="mt-4">
                          <v-btn
                            block
                            color="primary"
                            @click="applySuggestion"
                            outlined
                            >{{ __("aplicar", "productbudget") }}</v-btn
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-textarea>
              </ValidationProvider>
              <ValidationProvider
                name="final_report.observation"
                v-slot="{ errors }"
              >
                <v-textarea
                  outlined
                  :label="__('observações de sinistro', 'final_report')"
                  :rows="5"
                  auto-grow
                  v-model="lead_form.observation"
                  :error-messages="errors"
                  :readonly="editMode"
                ></v-textarea>
              </ValidationProvider>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="action-buttons">
        <v-btn
          fab
          color="primary"
          @click="save"
          :loading="saving_report"
          :disabled="!$store.getters['auth/getScope']('leads.final_report.add')"
          v-if="createMode"
        >
          <v-icon> mdi-content-save-move</v-icon>
        </v-btn>
        <v-btn
          fab
          color="secondary"
          :href="report.attachment && report.attachment.public_url"
          target="_blank"
          v-if="report != null && report.attachment != null"
        >
          <v-icon> mdi-file-find </v-icon>
        </v-btn>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { index as indexProducts } from "@/services/products/products";
import {
  show as showReport,
  storeEletricRisk,
  showLatestReport,
} from "@/services/final_reports";

import MoneyInput from "@/components/MoneyInput";
import { indexAvailableOriginTypes } from "@/services/lead";
import i18n from "@/mixins/i18n";

export default {
  components: { MoneyInput },
  mixins: [i18n],
  data: function () {
    return {
      lead_form: {
        established_facts: "",
        origin_type_id: null,
        needs_research: null,
        research_extimated_price: null,
        observation: null,
        analysis_scheduling_types: null,
        franchise_total_value: null,
        has_no_franchise_total_value: false,
        observation: null,
        capital: null,
      },

      fetching_report: false,
      saving_report: false,
      report: {},

      available_origin_types: [],
      fecthing_available_origin_types: false,

      errors: [],
      suggestion_products: [],

      products: [],
    };
  },
  methods: {
    loadLeadForm() {
      const lead = this.createMode ? this.lead : this.report.payload;
      this.lead_form = {
        ...this.lead_form,
        origin_type_id: lead?.origin_type_id,
        observation: lead?.final_report?.observation,
        established_facts: lead?.established_facts,
        needs_research: lead?.default_service?.needs_research,
        research_extimated_price:
          lead?.default_service?.research_extimated_price,
        analysis_scheduling_types:
          lead?.default_service?.analysis_scheduling_types,
        franchise_total_value: lead?.default_service?.franchise_total_value,
        has_no_franchise_total_value:
          lead?.default_service?.has_no_franchise_total_value,
        capital: lead?.capital,
      };
    },
    updateLead(lead) {
      this.$store.commit("setLead", {
        ...this.lead,
        origin_type_id: lead?.origin_type_id,
        observation: lead?.final_report?.observation,
        established_facts: lead?.established_facts,
        default_service: {
          ...this.default_service,
          needs_research: lead?.default_service?.needs_research,
          research_extimated_price:
            lead?.default_service?.research_extimated_price,
          analysis_scheduling_types:
            lead?.default_service?.analysis_scheduling_types,
          franchise_total_value: lead?.default_service?.franchise_total_value,
          has_no_franchise_total_value:
            lead?.default_service?.has_no_franchise_total_value,
        },
        capital: lead?.capital,
      });
    },
    async fetchFinalReport() {
      const report_id = this.$route.params["final_report_id"];

      if (report_id) {
        this.fetching_report = true;
        const response = await showReport(
          this.$route.params["lead_number"],
          report_id
        );
        this.report = response;
        this.lead_form.established_facts = response.established_facts;
        this.fetching_report = false;
        this.loadLeadForm();
      }
    },
    async fetchProducts() {
      this.fetching_report = true;
      const response = await indexProducts(this.$route.params["lead_number"]);
      this.products = response;
      this.fetching_report = false;
    },
    applySuggestion() {
      this.lead_form.established_facts = this.suggestion_products.reduce(
        function (start, product) {
          const prev = start ? start + "\n\n" : "";
          return prev + `${product.name} - ${product.technical_analysis}`;
        },
        null
      );
    },
    setErrors(data) {
      if (data) {
        this.$store.commit("sendMessage", {
          text: data.message,
          color: "red",
        });
      }
    },
    async save() {
      this.saving_report = true;
      try {
        if (this.createMode) {
          const response = await this.create();
          this.report.attachment = response.attachment;
          this.updateLead(response.payload);
        }
      } catch (e) {
        if (e?.response?.data?.errors) {
          this.$refs.form.setErrors(e?.response?.data.errors);
          this.errors = this.prepareErrors(e?.response.data.errors);

          if (this.errors.length > 0) {
            this.$vuetify.goTo(550);
          }
        }
        if (e?.response?.data?.message) {
          this.$store.commit("sendMessage", {
            text: e?.response?.data?.message,
            color: "red",
          });
        }
      } finally {
        this.saving_report = false;
      }
    },
    async create() {
      const response = await storeEletricRisk(this.lead?.id, {
        final_report: {
          ...this.lead_form,
        },
      });
      this.$store.commit("sendMessage", {
        text: this.$capitalize(
          this.$tc("model.final_report_report_created_successfully")
        ),
        color: "green",
      });
      this.$router.push({ name: "lead_final_reports" });
      return response;
    },

    async loadAvailableOriginTypes() {
      this.fecthing_available_origin_types = true;

      try {
        const response = await indexAvailableOriginTypes(
          this.$route.params["lead_number"]
        );
        this.available_origin_types = response;
      } finally {
        this.fecthing_available_origin_types = false;
      }
    },

    async loadLastFinalReport() {
      this.fetching_report = true;

      try {
        const response = await showLatestReport(
          this.$route.params["lead_number"]
        );
        this.lead_form.observation =
          response?.payload?.final_report?.observation;
      } finally {
        this.fetching_report = false;
      }
    },
    prepareErrors(errors) {
      if (!errors) return;

      const preparedErrors = [];

      for (const field in errors) {
        if (errors[field].length) {
          preparedErrors.push(errors[field][0]);
        }
      }

      return preparedErrors;
    },
    setHasNoFranchise(value) {
      if (value > 0) {
        this.lead_form.has_no_franchise_total_value = false;
      }
    },
    ...mapActions("scheduling_types", ["loadSchedulingTypes"]),
  },
  computed: {
    editMode() {
      return this.$route.params["final_report_id"] != null;
    },
    createMode() {
      return !this.editMode;
    },
    pageTitle() {
      if (this.editMode) return this.report?.attachment?.description;

      return this.$capitalize(this.$tc("model.final_report_new_report_title"));
    },
    lead() {
      return this.$store.getters.getLead;
    },
    final_report_id() {
      return this.$route.params["final_report_id"];
    },
    products_with_technical_analysis() {
      return this.products.filter((product) => product.technical_analysis);
    },
    ...mapState("scheduling_types", [
      "scheduling_types",
      "loading_scheduling_types",
    ]),
  },
  created() {
    this.loadSchedulingTypes();
    this.loadAvailableOriginTypes();
    this.fetchFinalReport();
    this.fetchProducts();
    this.loadLeadForm();

    if (!this.editMode) {
      this.loadLastFinalReport();
    }
  },
  watch: {
    lead: function (value, oldValue) {
      const old_lead_id = oldValue?.id;
      const lead_id = value?.id;

      if (old_lead_id == null && lead_id != null) {
        this.loadLeadForm();
      }
    },
    "lead_form.has_no_franchise_total_value": function (newValue) {
      if (newValue) {
        this.lead_form.franchise_total_value = 0.0;
      }
    },
    "lead_form.franchise_total_value": function (newValue) {
      if (newValue > 0) {
        this.lead_form.has_no_franchise_total_value = false;
      }
    },
  },
};
</script>

<style>
.action-buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 4;
  height: 120px;
  right: 16px;
  bottom: 16px;
  position: fixed;
}
</style>
